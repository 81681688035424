<template>
  <div class="exam_custom_calendar_box">
    <el-popover
      placement="top"
      :width="290"
      trigger="click"
      v-model:visible="show">
      <template #reference>
        <el-input
          class="custom_date_picker"
          v-model="selectDay"
          :clearable="true"
          prefix-icon="el-icon-date"
          placeholder=""></el-input>
      </template>
      <calendar @close="handleSelect"></calendar>
    </el-popover>
  </div>
</template>
<script>
import Calendar from './Calendar.vue';

export default {
  name: 'examCalendar',
  components: { Calendar },
  props: {
    defaultValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: new Date(),
      show: false,
      selectDay: '',
    };
  },
  watch: {
    defaultValue(val) {
      this.selectDay = this.formatDate(val);
    },
    immediate: true,
  },
  methods: {
    handleSelect(val) {
      this.show = false;
      this.selectDay = this.formatDate(val.date);
      this.$emit('refreshValue', val);
    },
    formatDate(d) {
      const date = new Date(d);
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
  },
};

</script>
<style lang="scss">
  .exam_custom_calendar_box{
    width:200px;
    height:32px;
    position:relative;

    .custom_date_picker{
      width:200px;
      margin-bottom: 13px;
    }
    .el-input__inner{
      height:32px;
      line-height: 32px;
    }
    .el-input__icon, .el-icon-date{
      line-height: 32px;
    }
    .calendar_popover{
      width:350px;
      box-shadow: 0px 0px 10px 0px rgba(202, 205, 214, 0.47);
      border-radius: 5px;
      overflow: hidden;
      position:absolute;
      left:0;
      right:0;
      margin:auto;
      z-index:2000;
      .el-calendar{

        .cell_day{
          width:30px;
          height:30px;
          line-height: 30px;
          text-align: center;
          margin:auto;
          font-size:15px;
          color:#363D51;
          font-family: BebasNeue-Regular;
          font-weight: 500;
        }
        .exam_day{
          width:4px;
          height:4px;
          background:#3377FF;
          border-radius:4px;
          margin:3px auto 0;
        }
        .is-selected{
          border-radius: 50%;
          background:#FFAA1E;
          color:#FFF;
        }
        .el-calendar__header{
          /*display:none;*/
          .el-calendar__title{
            display:none;
          }
          .el-calendar__button-group{
            margin:auto;
          }
        }
        .el-calendar__body{
          width:350px;
          height:343px;
          .el-calendar-table thead th{
            font-size: 15px;
            color:#A7AABA;
            font-family: BebasNeue-Regular;
            font-weight: 600 !important;
          }
          .prev,.current,.next{
            border:none;
            background:#FFF;
          }
          .el-calendar-day{
            width:46px;
            height:46px;
            border:none;
          }
        }
      }
    }

  }

</style>
