<template>
  <div class="common_text_form_container" v-if="showBox">
    <p class="text_form_item" v-for="(item) in list" :key="item.key" v-show="!item.hide">
      <span class="text_label">{{item.label}}</span>
      <span v-if="canEdit">
        <el-date-picker
            v-if="item.type==='date'"
            v-model="item.value"
            type="date"
            :disabled-date="item.disabledDate"
            placeholder="">
        </el-date-picker>
        <exam-calendar
          v-else-if="item.type==='calendar'"
          :default-value="item.value"
          @refreshValue="(val)=>valueChange(val,item.key)"
        ></exam-calendar>
         <el-radio-group v-else-if="item.type==='radio'" v-model="item.value">
            <el-radio
              v-for="it in  item.options"
              :key="it.label"
              :label="it.label">{{it.value}}</el-radio>
          </el-radio-group>
        <el-input v-else v-model="item.value" placeholder=""></el-input>
      </span>
      <span v-else class="text_content">
        {{item.key==='sex'?wrapSex(item):item.value}}
      </span>
    </p>
  </div>
</template>
<script>
import ExamCalendar from './EaxmCalendar.vue';

export default {
  name: 'textForm',
  components: { ExamCalendar },
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showBox() {
      return this.list.filter((item) => !item.hide).length;
    },
  },
  methods: {
    valueChange(val, key) {
      const l = this.list.slice();
      l.forEach((item, index) => {
        if (item.key === key) { l[index].value = val.date; l[index].examId = val.examDateId; }
      });
    },
    wrapSex(item) {
      const obj = { 1: '男', 2: '女', 3: '保密' };
      return obj[item.value];
    },
  },
};
</script>
<style lang="scss" >
  .common_text_form_container{
    padding:20px;
    background:#FFF;
    margin-bottom:20px;
    .text_form_item{
      height: 22px;
      line-height: 22px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom:15px;
      display:flex;
      flex-direction: row;
      align-items: center;
      &:nth-last-child(1){
        margin-bottom:0;
      }
      .text_label{
        width:85px;
        color: #72777E;

      }
      .text_content{
        color: #4E596F;
      }
    }
    .el-input__inner{
      height:30px;
      line-height: 30px;
    }
    .el-input__icon, .el-icon-date{
      line-height: 30px ;

    }
    .common_text_form_container ,.el-input__inner{
      width:178px;
    }
  }

</style>
