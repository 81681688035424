<template>
  <div class="hcp_custom_calendar_box">
    <p class="tool_bar">
      <span class="prev_icon" @click="changeMonth('prev')"></span>
<!--      <span class="left_icon"></span>-->
      <span>{{getMonth()}}</span>
<!--      <span class="right_icon"></span>-->
      <span class="next_icon"  @click="changeMonth('next')"></span>
    </p>
    <p class="date_tip">
      <span>笔考<span class="dot"></span></span>
    </p>
    <ul class="week_title">
      <li v-for="title in titleColumns" :key="title">{{title}}</li>
    </ul>
    <ul class="week_days">
      <li
        v-for="(day,index) in monthDays"
        :key="index"
        :class="day.status"
        @click="handleSelect(index,day)">
        <div
          class="day_value"
          :class="selectIndex===index?'selected':null">
          {{day.number}}
        </div>
        <div v-show="day.isExamDay" class="day_symbol"></div>
      </li>
    </ul>
  </div>
</template>
<script>
import { connectState } from '../utils/wyUtil';

export default {
  name: 'calendar',
  props: {
  },
  data() {
    return {
      titleColumns: ['日', '一', '二', '三', '四', '五', '六'],
      monthDays: this.calcCurMonthInfo(),
      isExamDate: false,
      selectDay: {},
      selectIndex: null,
      selectMonth: new Date().getMonth() + 1, // 当前月份为0，前-1，后+1
      currentMonth: new Date().getMonth() + 1,
      selectYear: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    ...connectState('login', ['examDates']),
  },
  watch: {
    examDates: {
      handler(val) {
        if (this.monthDays.length && val.length) {
          this.initData(this.monthDays, val);
        }
      },
      immediate: true,
    },
  },
  created() {
  },
  methods: {
    initData(monthDays, examDays) {
      const list = monthDays.slice();
      list.forEach((perDay, index) => {
        const temp = examDays.find((item) => perDay.status === 'available'
          && new Date(item.date).getDate() === perDay.number);
        list[index].isExamDay = !!temp;
        if (temp) {
          list[index].examDateId = temp.examDateId;
          list[index].date = temp.date;
        }
      });
      this.monthDays = list;
    },
    calcCurMonthInfo(y, m) {
      const nextY = y || 0;
      const nextM = m || 0;
      const date = new Date();
      const year = date.getFullYear() + nextY;
      const month = date.getMonth() + 1 + nextM;// +1 dangyue

      const lastDay = new Date(year, month, 0).getDate();
      const firstWeekDay = new Date(year, month - 1, 1).getDay();
      const lastWeekDay = new Date(year, month, 0).getDay();
      const prevLastDay = new Date(year, month - 1, 0).getDate();

      /** 前缺省数据 */
      const prevDays = firstWeekDay === 0 ? 0 : firstWeekDay;
      const prevMonthDate = [...new Array(prevDays).keys()];
      const prevArray = prevMonthDate.map((val, index) => {
        const obj = { status: 'prev', number: prevLastDay - prevDays + index + 1 };
        return obj;
      });
      /** 后缺省数据 */
      const nextDays = lastWeekDay === 0 ? 0 : 6 - lastWeekDay;
      const nextMonthDate = [...new Array(nextDays).keys()];
      const nextArray = nextMonthDate.map((val, index) => {
        const obj = { status: 'next', number: index + 1 };
        return obj;
      });
      /** 当前月份数据 */
      const curMonthDate = [...new Array(lastDay).keys()];
      const curArray = curMonthDate.map((val) => {
        const obj = { status: 'available', number: val + 1 };
        return obj;
      });

      const calendarArray = prevArray.concat(curArray, nextArray);

      const len = calendarArray.length;
      let notRequiredArray = [];
      /** ui图上显示7行7列，不足的补齐 */
      if (len < 42) {
        const last = calendarArray[len - 1].number;
        notRequiredArray = [...new Array(42 - len).keys()].map((val, index) => ({ status: 'next', number: last >= lastDay ? index + 1 : last + index + 1 }));
      }
      console.log('----', calendarArray.concat(notRequiredArray));
      return calendarArray.concat(notRequiredArray);
    },
    handleSelect(index, item) {
      if (item.status !== 'available' || !item.isExamDay) { return false; }
      this.selectIndex = index;
      this.selectDay = item;
      this.$emit('close', item);
      return true;
    },
    getMonth() {
      return `${this.selectYear}-${this.selectMonth}`;
    },
    changeMonth(type) {
      const num = type === 'prev'
        ? this.selectMonth - 1 : this.selectMonth + 1;
      if (num < 1) {
        this.selectMonth = 12;
        this.selectYear -= 1;
      } else if (num > 12) {
        this.selectMonth = 1;
        this.selectYear += 1;
      } else {
        this.selectMonth = num;
      }
      const nextY = this.selectYear - this.currentYear;
      const nextM = this.selectMonth - this.currentMonth;
      this.monthDays = this.calcCurMonthInfo(nextY, nextM);
      this.$store.dispatch('login/queryExamDates', {
        time: `${this.selectYear}-${this.selectMonth}-1`,
        examType: 2,
      });
    },
  },

};
</script>
<style lang="scss" scoped>
  .hcp_custom_calendar_box{
    width: 262px;
    background: #FFFFFF;
    padding:15px 5px 0;
    .tool_bar{
      height:22px;
      line-height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: #2A2A2B;
      text-align: center;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      margin-bottom:5px;
      .prev_icon,.next_icon{
        width:12px;
        height:10px;
        cursor: pointer;
        &:hover{
          opacity:0.5;
        }
      }
      .prev_icon{
        background:url('../assets/img/prev_double.png') 0 0 no-repeat;
        background-size:100% 100%;
      }
      .next_icon{
        background:url('../assets/img/next_double.png') 0 0 no-repeat;
        background-size:100% 100%;
      }
      .left_icon,.right_icon{
        width: 6px;
        height: 10px;
        cursor: pointer;
        &:hover{
          opacity:0.5;
        }
      }
      .left_icon{
        background:url('../assets/img/prev_icon.png') 0 0 no-repeat;
        background-size:100% 100%;
      }
      .right_icon{
        background:url('../assets/img/next_icon.png') 0 0 no-repeat;
        background-size:100% 100%;
      }

    }
    .date_tip{
      width:100%;
      padding-right:15px;
      display:inline-block;
      height:20px;
      line-height: 20px;
      font-size: 12px;
      text-align: right;
      color:#A7AABA;
      .dot{
        display:inline-block;
        width:4px;
        height:4px;
        border-radius: 3px;
        background:#3377FF;
        vertical-align: middle;
        margin-left:5px;
      }
    }
    .week_title{
      height:30px;
      line-height: 30px;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      font-size: 12px;
      font-weight: 600;
      color: #A7AABA;
    }
    .week_days{
      display:flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
      padding-top:10px;
      li{
        width:36px;
        height:42px;
        padding:0 3px;

        .day_value{
          width:28px;
          height:28px;
          line-height: 28px;
          text-align: center;
          font-size: 14px;
          font-family: BebasNeue;
          font-weight: 500;
        }
        .selected{
          background: #FFAA1E;
          color:#FFF;
          border-radius: 50%;
        }
        .day_symbol{
          width:4px;
          height:4px;
          background:#3377FF;
          border-radius: 50%;
          margin:2px auto;
        }
      }
      .prev,.next{
        color:#A7AABA;
      }
      .available{
        color:#363D51;
        cursor: pointer;
        .day_value{
          &:hover{
            background:rgba(255, 170, 30, 0.3);
            border-radius: 50%;
          }
        }
      }
    }
  }

</style>
